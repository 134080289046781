<template>
  <div class="promo-page promo-companies">
    <div
      v-if="!planIsLoading"
      class="promo-page-navigation"
    >
      <the-navigation />
    </div>
    <div
      class="promo-page-content"
      :class="{'promo-page-content-has-overlay': isCompanyMediaBlockOverlayVisible}"
    >
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import TheNavigation from '@/_modules/promo-hall/components/the-navigation';

export default {
  name: 'promo-page-events-main',
  components: {
    TheNavigation,
  },
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planJson: state => state.seatplanStore.planJson,
      planIsLoading: state => state.seatplanStore.isLoading,
    }),
    ...mapGetters({
      isCompanyMediaBlockOverlayVisible: 'promoPageStore/isMediaBlockOverlayVisible',
    }),
  },
  watch: {
    'planJson': {
      immediate: true,
      handler() {
        if (
          this.$route.params.external_id
          || (
            this.planEventId === this.$route.params.eventId
            && !this.planIsLoading
            && !this.planJson
          )
        ) {
          this.$store.dispatch('promoStore/setCompaniesListType', 'all');
        }
      },
    },

  },
  created() {
    this.$store.dispatch('promoStore/promoPageListAll', {
      event_id: this.$route.params.eventId,
    });

    this.$store.dispatch('seatplanStore/getSeatplanJson', {
      eventId: this.$route.params.eventId,
    });

    if (this.$route.params.external_id) {
      // TODO: leave only one store for this
      this.$store.dispatch('promoStore/setCompaniesListType', 'all');
      this.SET_VIEW_TYPE('list');
      this.SET_LIST_TYPE('all');
    }
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations({
      SET_VIEW_TYPE: 'promoPageStore/SET_VIEW_TYPE',
      SET_LIST_TYPE: 'promoPageStore/SET_LIST_TYPE',
    })
  }
};
</script>

<style scoped lang="scss">
.promo-companies {

  .promo-page-content {
    padding-top: 33px;

    &-has-overlay {
      z-index: $z-index-nav-bar + 1; // see AW-1875
    }
  }

}
</style>
